<template>
  <div class="req-temp-container">
    <!-- Payment Request Template List card starts here -->
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Templates</h3>
        </div>
      </div>
      <div class="flex justify-end">
        <vs-button
          flat
          v-round
          class="only-border-btn"
          size="medium"
          type="border"
          @click="templateOptions = true"
        >
          <plus-icon size="1.5x" class="button-icon"></plus-icon>
          New Template
        </vs-button>
      </div>

      <vs-table :data="paymentRequestTemplates" class="mt-5 stripes">
        <template slot="thead">
          <vs-th> Name </vs-th>
          <vs-th> Account</vs-th>
          <vs-th> Payment Options</vs-th>
          <vs-th> Type</vs-th>
          <vs-th v-if="merchantGroups.length > 0"> Status</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.templateName">
              {{ tr.templateName }}
            </vs-td>
            <vs-td :data="tr">
              {{ tr.bank ? tr.bank.displayName || tr.bank.accountName : "" }}
            </vs-td>
            <vs-td :data="tr.paymentOptions">
              {{ paymentRequestType(tr.paymentOptions[0]) }}
            </vs-td>
            <vs-td :data="tr.requestTemplateId">
              {{ tr.templateType }}
            </vs-td>
            <vs-td v-if="merchantGroups.length > 0" :data="tr.status">
              <span :class="changeStatusColor(tr)">
                {{
                  tr.status === "Archived"
                    ? "Archived"
                    : tr.assignedToGroup == 0
                    ? "Not assigned"
                    : "Assigned"
                }}
              </span>
            </vs-td>
            <vs-td v-if="tr.status !== 'Archived'" :data="tr">
              <a class="pl-5 pr-5" @click="editTemplate(tr._id, tr.templateType)">Edit</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
    <delete-popup
      :popup-active="popupDelActive"
      :warningContent="warningContent"
      :deleteWhat="deleteWhat"
      :buttonText="buttonText"
      :found="found"
      @handleDeleteClick="delActions"
    ></delete-popup>

    <!-- Payment Request Template List card ends here -->

    <!-- popup ends -->

    <!-- custom field create popup form starts here-->
    <vs-popup
      class="req-temp-options"
      title="What type of template would you like to create?"
      :active.sync="templateOptions"
    >
      <vs-row class="justify-start popup-radios flex-wrap">
        <vs-col class="mr-5 mb-5 w-auto">
          <div
            class="radio-card simpleadv-btn"
            @click="activateTemplate('simple')"
            :class="{ active: templateType == 'simple' }"
          >
            <vs-row>
              <vs-col
                vs-w="10"
                vs-sm="10"
                vs-lg="10"
                vs-xs="10"
                class="radio-label"
              >
                <p class="text-xl">Simple</p>
              </vs-col>
              <vs-col
                vs-w="2"
                vs-sm="2"
                vs-lg="2"
                vs-xs="2"
                class="flex justify-center"
              >
                <vs-radio
                  v-model="templateType"
                  vs-value="simple"
                  vs-name="simple"
                >
                </vs-radio>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <span class="radio-info list-info">
                  <p class="text-sm mb-4 font-normal">Allows you to:</p>
                  <ul class="text-sm font-normal disc-list">
                    <li class="mb-1">Set payment options</li>
                    <li class="mb-1">Link a bank account</li>
                    <li class="mb-1">Add payment page branding</li>
                    <li class="mb-1">Set message to customer</li>
                    <li class="mb-1">Configure redirections</li>
                    <li class="mb-1">Set notifications</li>
                    <li class="mb-1">Set custom sender</li>
                    <li class="mb-1">Set custom contact details</li>
                  </ul>
                </span>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
        <vs-col class="w-auto mr-5 mb-5">
          <div
            class="radio-card simpleadv-btn"
            @click="activateTemplate('advance')"
            :class="{ active: templateType == 'advance' }"
          >
            <vs-row>
              <vs-col
                vs-w="10"
                vs-sm="10"
                vs-lg="10"
                vs-xs="10"
                class="radio-label"
              >
                <p class="text-xl">Advanced</p>
              </vs-col>
              <vs-col
                vs-w="2"
                vs-sm="2"
                vs-lg="2"
                vs-xs="2"
                class="flex justify-center"
              >
                <vs-radio
                  v-model="templateType"
                  vs-value="advance"
                  vs-name="advance"
                >
                </vs-radio>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <span class="radio-info list-info">
                  <p class="text-sm mb-4 font-normal leading-tight">
                    Allows you to do everything in the Simple template plus:
                  </p>
                  <ul class="text-sm font-normal disc-list">
                    <li class="mb-1">Configure custom data fields</li>
                    <li class="mb-1">Set request expiry time</li>
                  </ul>
                </span>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
        <vs-col class="w-auto mb-5">
          <div
            class="radio-card simpleadv-btn"
            @click="activateTemplate('api')"
            :class="{ active: templateType == 'api' }"
          >
            <vs-row>
              <vs-col
                vs-w="10"
                vs-sm="10"
                vs-lg="10"
                vs-xs="10"
                class="radio-label"
              >
                <p class="text-xl">API Custom</p>
              </vs-col>
              <vs-col
                vs-w="2"
                vs-sm="2"
                vs-lg="2"
                vs-xs="2"
                class="flex justify-center"
              >
                <vs-radio v-model="templateType" vs-value="api" vs-name="api">
                </vs-radio>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <span class="radio-info list-info">
                  <p class="text-sm mb-4 font-normal leading-tight">
                    Allows you to control visibility of transactions created by
                    your integrated systems, at a group level.
                  </p>
                  <p class="text-sm font-normal leading-tight">
                    API requests that include this template ID will be
                    viewable/editable in groups that have access to this
                    template.
                  </p>
                </span>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
      <vs-row class="justify-end">
        <div class="flex items-center flex-wrap">
          <vs-button
            class="btn-primary mr-5 text-sm"
            v-round
            size="large"
            @click="configureTemplate()"
            :disabled="!enableConfigure"
            >Configure template</vs-button
          >
          <a @click="configureCancel" class="btn mid-blue text-sm">Cancel</a>
        </div>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DeletePopup from "@/views/components/DeletePopup";
import { PlusIcon } from "vue-feather-icons";

export default {
  components: { DeletePopup, PlusIcon },
  data() {
    return {
      warningContent: `There are pending requests linked to this template. By deleting
              this template you will also cancel any pending payment requests
              sent from this template.`,
      deleteWhat: `request template`,
      buttonText: `Delete Template`,
      paymentRequestTemplates: [],
      popupDelActive: false,
      deleteRTid: "",
      pRfromTemplateId: [],
      found: false,
      templateOptions: false,
      templateType: "",
      enableConfigure: false,
      plans: [],
      merchantGroups: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId;
    },
  },
  mounted() {
    this.checkLogin().then((result) => {
      this.getRequestTemplateByMerchantId();
      this.getPaylaterPlan();
      this.getMerchantGroups(this.partnerId);
    });

  },
  methods: {
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("paymentRequestTemplate", [
      "fetchRequestTemplateByMerchantId",
      "updateRequestTemplateById",
    ]),

    ...mapActions("paymentRequest", ["fetchPaymentRequestListByReqTemId"]),

    ...mapActions("merchantSettings", ["fetchPartnerDetailById"]),
    ...mapActions("partner", ["getPaymentPlanByMerchatId"]),
    ...mapActions("merchantGroup", ["fetchMerchantGroupByMerchantId"]),

    async getPRByReqTemId(reqTemId) {
      this.fetchPaymentRequestListByReqTemId(reqTemId).then((res) => {
        this.pRfromTemplateId = res.data.data;
        for (let i = 0; i < this.pRfromTemplateId.length; i++) {
          if (["Pending", "Re-sent"].includes(this.pRfromTemplateId[i].paymentStatus)) {
            this.found = true;
            break;
          }
        }
      });
    },

    async deleteRequestTemplate() {
      let obj = {
        id: this.deleteRTid,
        payload: { merchantId: this.partnerId, deleted: true },
      };
      this.updateRequestTemplateById(obj).then((res) => {
        this.getRequestTemplateByMerchantId();
      });
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteRequestTemplate(this.delIndex);
          this.found = false;
          break;
        case "cancel":
          this.popupDelActive = false;
          this.delIndex = "";
          this.found = false;
          break;
        default:
          this.popupDelActive = false;
          this.delIndex = "";
          this.found = false;
      }
    },
    async getRequestTemplateByMerchantId() {
      let data = {
        merchantId: this.partnerId,
        type: "template",
      };
      await this.fetchRequestTemplateByMerchantId(data).then((res) => {
        this.paymentRequestTemplates = res.data.data;
        this.paymentRequestTemplates = this.paymentRequestTemplates.map(
          (element) => ({
            ...element,
            companyName: element.hasOwnProperty("header")
              ? element.header.companyName
              : "",
          })
        );
      }).catch(err=> {
        this.$vs.notify({
            title: 'Template',
            text: err.response.data.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            position: "top-right",
          })
      });
    },
    editTemplate(id, templateType) {
      if (templateType == "simple") {
        this.$router.push({
          name: "simple-payment-request-template-edit",
          params: { id: id },
        });
      }
      if (templateType == "advance") {
        this.$router.push({
          name: "advance-payment-request-template-edit",
          params: { id: id },
        });
      }
      if (templateType == "api") {
        this.$router.push({
          name: "api-payment-request-template-edit",
          params: { id: id },
        });
      }
    },

    handleDelete(id) {
      this.getPRByReqTemId(id);
      this.deleteRTid = id;
      this.popupDelActive = true;
    },
    activateTemplate(el) {
      // if(el == "simple"){
      //   this.templateType = "simple"
      // }else if('advance'){
      //   this.templateType = "advance";
      // }
      this.templateType = el;
      this.enableConfigure = true;
    },
    configureTemplate() {
      if (this.templateType == "simple") {
        this.$router.push({
          name: "simple-payment-request-template-create",
          params: { templateType: this.templateType },
        });
      }
      if (this.templateType == "advance") {
        this.$router.push({
          name: "advance-payment-request-template-create",
          params: { templateType: this.templateType },
        });
      }
      if (this.templateType == "api") {
        this.$router.push({
          name: "api-payment-request-template-create",
          params: { templateType: this.templateType },
        });
      }
    },
    configureCancel() {
      this.templateOptions = false;
      this.templateType = "";
      this.enableConfigure = false;
    },
    paymentRequestType(val) {
      if (val && val.requestType == "recurring") {
        return "Recurring";
      } else if (val && val.requestType == "one-off") {
        if (
          val.requestOptions.includes("pay-later") &&
          val.requestOptions.includes("pay-now")
        ) {
          const planName =
            val.payLaterPlan.length > 0
              ? this.getPlanName(val.payLaterPlan[0])
              : "Pay Later";
          return "Pay Now, " + planName;
        } else if (val.requestOptions.includes("pay-now")) {
          return "Pay now";
        } else if (val.requestOptions.includes("pay-later")) {
          return "Pay later";
        }
      } else {
        return "";
      }
    },
    async getPaylaterPlan() {
      await this.getPaymentPlanByMerchatId(this.partnerId)
        .then((result) => {
          this.plans = result.data.data.payLaterPlans;
        })
        .catch((err) => {

        });
    },
    getPlanName(id) {
      let objIndex = this.plans.findIndex((obj) => obj.planId === id);
      const plan = objIndex >= 0 ? this.plans[objIndex].title : "";
      return plan;
    },

    changeStatusColor(tr) {
      let bgClass = "";

      const status =
        tr.status === "Archived"
          ? "Archived"
          : tr.assignedToGroup == 0
          ? "Not assigned"
          : "Assigned";

      if (status == "Archived") {
        bgClass = "badge archived";
      } else if (status == "Assigned") {
        bgClass = "badge success";
      } else bgClass = "badge warning";

      return bgClass;
    },

    async getMerchantGroups(partnerId) {
      await this.fetchMerchantGroupByMerchantId(partnerId)
        .then((res) => {
          this.merchantGroups = res.data.data.docs;
        })
        .catch((err) => {});
    },
  },
};
</script>
